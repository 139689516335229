<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              携手共进，风雨同舟——北京有为信通科技发展有限公司获得丰台科技园“创新十二条”2019年度（第二批）支持资金5万元。感谢中关村科技园区丰台区管委会的支持，大家的认可是我们前进的动力，2021新的起点，我们将勇攀高峰，再创佳绩！
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/reward/reward.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #9c9b9b;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #F4EFFD;
        color: #BB8DF0;
        font-size: 18px;
        padding: 20px 48px 20px 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>